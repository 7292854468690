import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from "@reach/router";
import { gsap } from "gsap";
import { Link, graphql, useStaticQuery } from "gatsby";
import logo_w from "../images/logo_w.png";
import logo_b from "../images/logo_b.png";

const MobileStickNav = ({ stickTop }) => {
  const location = useLocation();
  const navbar = useRef(null);
  const [ navOpen, setNavOpen ] = useState(false);
  const siteData = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
    }
  `);
  let pathPrefix = siteData.site.pathPrefix === "" ? "/" : siteData.site.pathPrefix;
  pathPrefix = pathPrefix === "/" ? "" : pathPrefix;

  useEffect(() => {
    gsap.from(navbar.current, {
      duration: 0.75,
      autoAlpha: 0,
      ease: "power3",
      y: -75,
      delay: 0.3
    });
  }, []);

  useEffect(() => {
    if(navOpen) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "scroll";
    }
  }, [navOpen]);

  const handleChange = (e) => {
    setNavOpen(e.target.checked);
  }

  return (
    <nav className={navOpen ? (stickTop ? "navbar stick-top active" : "navbar active") : (stickTop ? "navbar stick-top" : "navbar")} ref={navbar}>
      <div className="navbar__main">
        <Link to="/" className="navbar__logo"><img src={stickTop ? (navOpen ? logo_w : logo_b) : logo_b} alt="coolso"/></Link>
        <input className="navbar__toggle" type="checkbox" name="navToggle" id="navToggle" value={navOpen} onChange={handleChange} checked={navOpen} />
        <label htmlFor="navToggle" className="navbar__btn"><span className="navbar__burger"></span></label>
      </div>
      <ul className="navbar__list">
        <li className="navbar__item">
          <Link to="/" className={location.pathname === `${pathPrefix}/` ? "navbar__link current" : "navbar__link"}>Home</Link>
        </li>
        <li className="navbar__item">
          <Link to="/about" className={location.pathname === `${pathPrefix}/about` ? "navbar__link current" : "navbar__link"}>About</Link>
        </li>
        <li className="navbar__item">
          <Link to="/use-case" className={location.pathname === `${pathPrefix}/use-case` ? "navbar__link current" : "navbar__link"}>Use Case</Link>
        </li>
        <li className="navbar__item">
          <Link to="/news" className={location.pathname === `${pathPrefix}/news` ? "navbar__link current" : "navbar__link"}>News</Link>
        </li>
        <li className="navbar__item">
          <Link to="/contact" className={location.pathname === `${pathPrefix}/contact` ? "navbar__link current" : "navbar__link"}>Contact</Link>
        </li>
      </ul>
    </nav>
  )
}

export default MobileStickNav;
