import React, { useState, useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header";
import StickyHeader from "../components/stickyHeader";

const Contact = () => {
  const [ name, setName ] = useState("");
  const [ country, setCountry ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ company, setCompany ] = useState("");
  const [ message, setMessage ] = useState("");
  const [ nameErr, setNameErr ] = useState(false);
  const [ countryErr, setCountryErr ] = useState(false);
  const [ emailErr, setEmailErr ] = useState(false);
  const [ messageErr, setMessageErr ] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  const [reqAddress, setReqAddress] = useState("");
  const [contactSuccess, setContactSuccess] = useState(false);
  let timer;

  useEffect(() => {
    fetch("/api/get/contact")
      .then((res) => res.json())
      .then((data) => {
        const { rtCode, rtData: { contact } } = data;
        if(rtCode === "S00") {
          setContactInfo({
            contactAddress: contact.contactAddress,
            contactEmail: contact.contactEmail
          });
          setReqAddress(contact.contactAddress.replaceAll(" ", "+"));
        }
      })
      .catch((err) => console.log(err.message));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, country, email, company, message);
    resetErr();
    if(formValidation()) {
      fetch("/api/get/send-mail", {
        method: "POST",
        body: JSON.stringify({
          name, country, email, company, message
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .then(data => {
          setContactSuccess(true);
          resetForm();
          clearTimeout(timer);
          timer = setTimeout(() => {
            setContactSuccess(false);
          }, 5000);
        })
        .catch(err => console.error(err.message));
    }
  }

  const formValidation = () => {
    if(name.length === 0) {
      setNameErr(true);
      return false;
    }
    if(country.length === 0) {
      setCountryErr(true);
      return false;
    }
    if(email.length === 0) {
      setEmailErr(true);
      return false;
    }
    if(message.length === 0) {
      setMessageErr(true);
      return false;
    }
    return true;
  }

  const resetErr = () => {
    setNameErr(false);
    setCountryErr(false);
    setEmailErr(false);
    setMessageErr(false);
  }

  const resetForm = () => {
    setName("");
    setCompany("");
    setEmail("");
    setCountry("");
    setMessage("");
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <Header staticHeader />
      <StickyHeader distShort bgBlack />
      <section className="content">
        <div className="content__section container contact__content">
          <h2 className="heading__primary">Contact Us</h2>
          <p className="paragraph contact__intro">We welcome anyone interested in our technology to contact us and discussion possibility of cooperation and customization.</p>
          <div className="contact__info">
            <div className="contact__info--content">
              <h6 className="contact__info--title">Address</h6>
              <p className="contact__info--text">{contactInfo.contactAddress}</p>
              <h6 className="contact__info--title">Contact Person</h6>
              <p className="contact__info--text">{contactInfo.contactEmail}</p>
            </div>
            <div className="contact__info--map">
              <div className="map__container">
                {reqAddress.length !== 0 && <iframe src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_MAP_API_KEY}&q=${reqAddress}&zoom=16`} width="800" height="375" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>}
              </div>
            </div>
          </div>
          <h2 className="heading__primary">Leave a Message</h2>
          <form className="contact__form">
            {contactSuccess && <h5 className="contact__msg--success">Thank you for your contact!</h5>}
            <div className="contact__form-group">
              <div className="contact__form-control">
                <label htmlFor="name">Your Name<i>*</i></label>
                <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                {nameErr && <p className="contact__msg--error">Name should not be empty...</p>}
              </div>
              <div className="contact__form-control">
                <label htmlFor="country">Country<i>*</i></label>
                <input type="text" name="country" id="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                {countryErr && <p className="contact__msg--error">Country should not be empty...</p>}
              </div>
            </div>
            <div className="contact__form-group">
              <div className="contact__form-control">
                <label htmlFor="email">Email<i>*</i></label>
                <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                {emailErr && <p className="contact__msg--error">Email should not be empty...</p>}
              </div>
              <div className="contact__form-control">
                <label htmlFor="company">Company</label>
                <input type="text" name="company" id="company" value={company} onChange={(e) => setCompany(e.target.value)} />
              </div>
            </div>
            <div className="contact__form-control">
              <label htmlFor="message">Message<i>*</i></label>
              <textarea name="message" id="message" cols="30" rows="10" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
              {messageErr && <p className="contact__msg--error">Message should not be empty...</p>}
            </div>
            <button className="contact__submit" onClick={handleSubmit}>Submit</button>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
