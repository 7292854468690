import { useState, useEffect } from "react";

const useWindowSize = () => {
  const getSize = () => ({
    width: typeof window !== `undefined` ? window.innerWidth : 0,
    height: typeof window !== `undefined` ? window.innerHeight : 0
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    }
  }, []);

  return windowSize;
}

export default useWindowSize;