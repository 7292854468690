import React, { useRef, useEffect, useState } from 'react';
import MobileStickNav from './mobileStickNav';
import Navbar from './navbar';
import useWindowSize from "../hooks/useWindowSize";
import logo_b from "../images/logo_b.png";
import logo_w from "../images/logo_w.png";

const StickyHeader = ({ bgBlack, distShort }) => {
  const stickyHeader = useRef(null);
  const [ showStickyHeader, setShowStickyHeader ] = useState(false);
  const [ windowWidth, setWindowWidth ] = useState(0);
  const { height } = useWindowSize();

  useEffect(() => {
    let lastScrollY = window.screenY;
    setWindowWidth(window.offsetWidth);
    const stickyHeaderHandler = (e) => {
      let isScrollTop = (window.scrollY - lastScrollY) < 0;
      let divisor = height / 2.5 > 100 ? 2.5 : 2; // height除以2.5後的剩餘高度不得小於Navbar原本的高度
      // 在特別短的頁面加上distShort屬性，在更短的距離內即可顯示StickyHeader
      if(distShort ? window.scrollY <= height / divisor : window.scrollY <= height) {
        setShowStickyHeader(false);
      } else {
        if(isScrollTop) {
          setShowStickyHeader(true);
        } else {
          setShowStickyHeader(false);
        }
      }
      lastScrollY = window.scrollY;
    }
    window.addEventListener("scroll", stickyHeaderHandler);
    const imgEl = stickyHeader.current.querySelector(".navbar__logo img");
    const navbarBurber = stickyHeader.current.querySelector(".navbar__burger");
    if(bgBlack) {
      imgEl.src = logo_w;
      navbarBurber.classList.add("bg-white");
    } else {
      imgEl.src = logo_b;
      navbarBurber.classList.remove("bg-white");
    }

    return () => {
      window.removeEventListener("scroll", stickyHeaderHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={showStickyHeader ? "sticky-header active" : "sticky-header"} data-theme={bgBlack ? "black" : "white"} ref={stickyHeader}>
      <div className="container">
        {windowWidth > 850 ? <Navbar stickTop /> : <MobileStickNav stickTop />}
      </div>
    </div>
  )
};

export default StickyHeader;
